<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="400"
    :closeAble="false"
    :footer="false"
    @close="onClose"
  >
    <a-spin tip="Loading..." :spinning="spinning">
      <div class="account-order">
        <div class="order-info" v-if="orderDetail">
          <div class="order-info-box">
            <div class="info">
              <div class="control" v-if="orderDetail.customerName">
                <div class="label">预约姓名</div>
                <div class="value">
                  {{
                    orderDetail.customerName +
                    "(" +
                    ["未知", "先生", "女士"][orderDetail.customerSex] +
                    ")"
                  }}
                </div>
              </div>

              <div class="control" v-if="orderDetail.customerMobile">
                <div class="label">手机号码</div>
                <div class="value">{{ orderDetail.customerMobile }}</div>
              </div>

              <div class="control">
                <div class="label">就餐时间</div>
                <div class="value">
                  {{ $filters.formatDate(orderDetail.bookTime, "MM-DD HH:mm") }}
                </div>
              </div>

              <div class="control">
                <div class="label">就餐人数</div>
                <div class="value">{{ orderDetail.peopleCount }}</div>
              </div>

              <div class="control" v-if="orderDetail.customerRemark">
                <div class="label">就餐备注</div>
                <div class="value">{{ orderDetail.customerRemark }}</div>
              </div>
            </div>

            <div class="item title">
              {{ orderDetail.comboName }}
              <div class="item-right">
                ¥{{ orderDetail.comboPrice * orderDetail.peopleCount }}
              </div>
            </div>

            <div class="items">
              <div class="item" v-for="(v, k) in orderItems" :key="k">
                <div>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div>¥{{ v.sellingPrice }}</div>
                </div>
              </div>

              <div class="item" v-for="(v, k) in orderItems2" :key="k">
                <div>
                  <a-tag color="green" v-if="v.state === 0">加菜</a-tag>
                  <a-tag color="orange" v-if="v.state === 1">赠菜</a-tag>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div>¥{{ v.sellingPrice }}</div>
                </div>
              </div>

              <div class="item" v-for="(v, k) in orderItems3" :key="k">
                <div>
                  <a-tag color="blue">酒水</a-tag>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div>¥{{ v.sellingPrice }}</div>
                </div>
              </div>

              <div class="item" v-for="(v, k) in orderItems4" :key="k">
                <div>
                  <a-tag color="red">损耗</a-tag>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div>¥{{ v.sellingPrice }}</div>
                </div>
              </div>

              <div class="item" v-for="(v, k) in orderItems5" :key="k">
                <div>
                  <a-tag color="red" v-if="v.targetOrderId === orderId">{{v.fromTableName}}</a-tag>
                  <a-tag color="red" v-else>{{v.toTableName}}</a-tag>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div v-if="v.targetOrderId !== orderId">¥{{ v.sellingPrice }}</div>
                  <div v-else>¥0</div>
                </div>
              </div>
            </div>
          </div>

          <div class="total">
            <div>支付金额</div>
            <div class="price">
              <span class="rmb">¥</span>
              <b>{{ orderDetail.payAmount }}</b>

              <div class="moling" v-if="orderDetail.wipeOffAmount">
                <span class="del">¥{{ orderDetail.orderAmount }}</span>
                <span class="moling">抹零{{ orderDetail.wipeOffAmount }}</span>
              </div>
              <div class="zhekou" v-if="orderDetail.disCount">
                <span class="del">¥{{ orderDetail.orderAmount }}</span>
                <span style="margin-left: 5px">
                  折扣：{{ orderDetail.disCount }}折
                </span>
              </div>
              <div class="moling" v-if="orderDetail.savedAmount">
                <span class="del">¥{{ orderDetail.orderAmount }}</span>
                <span class="moling">抵扣{{ orderDetail.savedAmount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {
  CloseOutlined
} from '@ant-design/icons-vue'
import form from '@/mixins/form'
import { OrderClass } from '@/apis/order'

const order = new OrderClass()

export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {
    CloseOutlined
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const spinning = ref(false)
    const visible = ref(false)
    const title = ref()
    const orderDetail = ref()
    const orderId = ref('')

    const orderItems = ref([])
    const orderItems2 = ref([])
    const orderItems3 = ref([])
    const orderItems4 = ref([])
    const orderItems5 = ref([])

    const onClose = () => {
      spinning.value = false
      loading.value = false
      visible.value = false
    }

    const onGetOrder = (id) => {
      spinning.value = true
      order.detail(id).then((resp) => {
        orderDetail.value = resp

        // 必点品
        orderItems.value = resp.orderItems.filter((x) => x.dish.isMust)
        // 加菜或赠菜
        orderItems2.value = resp.orderItems.filter((x) => x.type === 4)
        // 酒水
        orderItems3.value = resp.orderItems.filter((x) => x.type === 2)
        // 损耗
        orderItems4.value = resp.orderItems.filter((x) => x.type === 3)
        orderItems5.value = resp.orderItems.filter((x) => x.type === 5)

        title.value = '“' + resp.tableName + '”结算单'
        spinning.value = false
      })
    }

    return {
      visible,
      loading,
      spinning,
      title,
      orderDetail,
      orderId,
      orderItems,
      orderItems2,
      orderItems3,
      orderItems4,
      orderItems5,

      onClose,
      onGetOrder
    }
  },

  methods: {
    open (orderId) {
      this.orderId = orderId
      this.onGetOrder(orderId)
      this.visible = true
    }
  }
})
</script>

<style lang="less" scoped>
.account-order {
  width: 100%;
  .order-info {
    width: 100%;

    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #1890ff;

    .order-info-box {
      height: 400px;
      overflow: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #d3eaff;
      }
    }

    .info {
      background: #d3eaff;
      padding: 10px 5px;

      .control {
        display: flex;
        justify-content: space-between;
        height: 30px;
        align-items: center;
        .label {
          color: #999;
        }
        .value {
          color: #333;
          font-weight: 600;
        }
      }
    }

    .item {
      padding: 5px;
      font-size: 12px;
      height: 40px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #666;

      .count {
        font-size: 10px;
      }

      .item-right {
        width: 40px;
      }

      &.title {
        background: #f8f8f8;
        border-bottom: 3px solid #ebebeb;
        font-size: 14px;
        color: #333;
        font-weight: 600;
        padding: 5px 10px;
      }
    }

    .items {
      padding: 0 5px;
    }

    .coupons{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      background: #ecf6ff;
      padding: 0 10px;

      .coupon-state{
        font-size: 12px;
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      background: #d3eaff;
      padding: 0 10px;
      .price {
        font-size: 20px;
        color: #f50;
        text-align: right;

        .rmb {
          font-size: 12px;
        }

        .del {
          text-decoration: line-through;
          font-size: 12px;
          color: #999;
        }

        .moling {
          font-size: 12px;
          color: #999;
          margin: 0 3px;
        }

        a {
          color: #333;
          font-size: 12px;
        }

        .zhekou {
          font-size: 12px;
          color: #999;
          margin: 0 3px;
        }
      }
    }
  }
}
</style>
